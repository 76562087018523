import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { AnalyticEvent } from '@app/core/analytics.service';
import { AppointmentAnalyticsProperty } from '@app/core/appointment-analytics-base.service';
import { LinksService } from '@app/core/links.service';
import { FLOW_APPOINTMENT_BOOKING } from '@app/core/mixpanel.constants';
import { PaymentMethod } from '@app/membership/settings/__generated__/payment-methods-graphql.service.types';
import { InsuranceCaptureService } from '@app/shared/insurance-capture/insurance-capture.service';
import { PaymentCaptureService } from '@app/shared/payment-capture/payment-capture.service';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';

export enum PaymentStep {
  CONFIRM_METHOD = 'confirm-method',
  COPAY_REQUIRED = 'copay-required',
  SELECT_METHOD = 'select-method',
  ADD_METHOD = 'add-method',
}

export enum ManagePaymentMethodsQueryParams {
  SOURCE = 'source',
  CONFIRMATION_ID = 'confirmationId',
  IS_NEW_INSURANCE = 'new',
}

export enum ManagePaymentMethodsSource {
  INSURANCE = 'insurance',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

export const ManagePaymentMethodsSourcePropertyMap: Record<ManagePaymentMethodsSource, string> = {
  [ManagePaymentMethodsSource.INSURANCE]: AppointmentAnalyticsProperty.AddInsurancePagePreBookingModalModule,
  [ManagePaymentMethodsSource.REVIEW]: AppointmentAnalyticsProperty.AppointmentSelectedPage,
  [ManagePaymentMethodsSource.CONFIRMATION]: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
} as const;

@Component({
  selector: 'om-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss'],
})
export class ManagePaymentMethodsComponent implements OnInit {
  PaymentStep = PaymentStep;
  currentStep: PaymentStep;
  showSkip: boolean;
  source: ManagePaymentMethodsSource;
  sourceProp: string;
  confirmationId: string | null;
  isNewInsurance: boolean;
  historyStack: PaymentStep[] = [];
  defaultCopayMethod: PaymentMethod | null;
  paymentMethods: PaymentMethod[];
  currentPaymentMethod: PaymentMethod;
  isCurrentMethodExpired: boolean;
  canSetDefault: boolean;
  copay: number;
  isFlagOptional: boolean;
  isFlagM2Enabled: boolean;
  isFlagM2Optional: boolean;
  protected loading = true;

  constructor(
    private readonly paymentCaptureService: PaymentCaptureService,
    private readonly insuranceCaptureService: InsuranceCaptureService,
    private readonly router: Router,
    private readonly links: LinksService,
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AppointmentAnalyticsService,
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParamMap;
    this.source = params.get(ManagePaymentMethodsQueryParams.SOURCE) as ManagePaymentMethodsSource;
    this.confirmationId = params.get(ManagePaymentMethodsQueryParams.CONFIRMATION_ID);
    this.isNewInsurance = params.get(ManagePaymentMethodsQueryParams.IS_NEW_INSURANCE) === 'true';

    this.sourceProp = ManagePaymentMethodsSourcePropertyMap[this.source];

    combineLatest([
      // Cache insurance data to prevent reactive changes due to delayed verification
      this.insuranceCaptureService.getPrimaryInsurance$().pipe(take(1)),
      this.paymentCaptureService.getPaymentMethods$(),
      this.paymentCaptureService.getPaymentCaptureFlagOptional$(),
      this.paymentCaptureService.getPaymentCaptureFlagM2Enabled$(),
      this.paymentCaptureService.getPaymentCaptureFlagM2Optional$(),
    ]).subscribe(([insurance, paymentMethods, isFlagOptional, isFlagM2Enabled, isFlagM2Optional]) => {
      this.copay = insurance.copay || 0;
      this.canSetDefault = this.source === ManagePaymentMethodsSource.INSURANCE && this.copay > 0;
      this.paymentMethods = paymentMethods;
      this.defaultCopayMethod = this.paymentMethods.find(method => method.isDefaultCopay);
      this.currentPaymentMethod = this.defaultCopayMethod || paymentMethods?.[0];
      this.isCurrentMethodExpired =
        this.currentPaymentMethod &&
        this.paymentCaptureService.isCardExpired(this.currentPaymentMethod.expYear, this.currentPaymentMethod.expMonth);
      this.isFlagOptional = isFlagOptional;
      this.isFlagM2Enabled = isFlagM2Enabled;
      this.isFlagM2Optional = isFlagM2Optional;
      this.loading = false;
      if (!this.currentStep) {
        const initialStep = this.getInitialStep();
        this.goToStep(initialStep);
      }
    });
  }

  goBack() {
    if (this.historyStack.length > 1) {
      // If there's local history, go back within component
      this.historyStack.pop();
      const step = this.historyStack.pop() as PaymentStep;
      this.goToStep(step);
    } else {
      // Else return to source
      this.goToSource();
    }
  }

  goToStep(step: PaymentStep) {
    this.historyStack.push(step);

    if (this.isFlagM2Enabled) {
      this.showSkip =
        this.isFlagM2Optional &&
        this.source === ManagePaymentMethodsSource.INSURANCE &&
        (!this.isCurrentMethodExpired
          ? step === this.historyStack.filter(s => s !== PaymentStep.CONFIRM_METHOD)[0] // first non-confirm step
          : step === this.historyStack[0]);
    } else {
      this.showSkip =
        this.isFlagOptional && this.source === ManagePaymentMethodsSource.INSURANCE && step === this.historyStack[0];
    }

    this.currentStep = step;
  }

  goToDestination() {
    if (this.source === ManagePaymentMethodsSource.INSURANCE) {
      // If part of booking flow, continue to review page
      this.router.navigate([this.links.appointmentReview]);
    } else {
      // Else return to source
      this.goToSource();
    }
  }

  private getInitialStep() {
    if (!this.isFlagM2Enabled) {
      return this.canSetDefault ? PaymentStep.COPAY_REQUIRED : PaymentStep.ADD_METHOD;
    }

    if (this.canSetDefault) {
      if (this.defaultCopayMethod) {
        return PaymentStep.CONFIRM_METHOD;
      } else if (this.isNewInsurance) {
        return PaymentStep.COPAY_REQUIRED;
      }
    }

    return PaymentStep.ADD_METHOD;
  }

  trackAnalytics(analytics: AnalyticEvent) {
    this.analyticsService.trackEventWithDefaultProperties({ ...analytics, flow: FLOW_APPOINTMENT_BOOKING });
  }

  private goToSource() {
    const routeMap = {
      [ManagePaymentMethodsSource.INSURANCE]: this.links.manageInsurance,
      [ManagePaymentMethodsSource.REVIEW]: this.links.appointmentReview,
      [ManagePaymentMethodsSource.CONFIRMATION]: this.confirmationId
        ? this.links.appointmentConfirmation(this.confirmationId)
        : this.links.home,
    };
    this.router.navigate([routeMap[this.source] || this.links.home]);
  }
}
