import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { AppointmentRoutingStateService } from '@app/appointment/appointment-routing-state-service/appointment-routing-state.service';
import { AppointmentRoutingStepName } from '@app/appointment/appointment-routing-state-service/appointment-routing-steps';
import {
  ManagePaymentMethodsQueryParams,
  ManagePaymentMethodsSource,
} from '@app/appointment/manage-payment-methods/manage-payment-methods.component';
import { FeatureFlagVariants } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { InsuranceCaptureService, InsuranceStatus } from '@app/shared/insurance-capture/insurance-capture.service';
import { PaymentCaptureService } from '@app/shared/payment-capture/payment-capture.service';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';
import { InsuranceSubmitEvent } from './add-insurance/add-insurance.component';

export enum InsuranceStep {
  CONFIRM_INSURANCE = 'confirm-insurance',
  ADD_INSURANCE = 'add-insurance',
}

export enum ManageInsuranceQueryParams {
  SOURCE = 'source',
  CONFIRMATION_ID = 'confirmationId',
}

export const ManageInsuranceSource = {
  REVIEW_BOOKING: 'review',
  BOOKING_CONFIRMATION: 'confirmation',
  APPOINTMENT_INVENTORY: 'inventory',
  SWITCH_TO_REMOTE: 'switchRemote',
  AMAZON: 'amazon',
} as const;
export type ManageInsuranceSource = typeof ManageInsuranceSource[keyof typeof ManageInsuranceSource];

@Component({
  selector: 'om-manage-insurance',
  templateUrl: './manage-insurance.component.html',
  styleUrls: ['./manage-insurance.component.scss'],
})
export class ManageInsuranceComponent implements OnInit {
  InsuranceStep = InsuranceStep;
  currentStep: InsuranceStep;
  protected loading = true;
  hasExistingNonSelfPayInsurance: boolean;
  // acts as a local state machine to manage navigation state within this component
  historyStack: InsuranceStep[] = [];
  paymentCaptureFlagEnabled: boolean;
  paymentCaptureFlag: FeatureFlagVariants;
  hasPaymentMethods: boolean;
  source: ManageInsuranceSource;
  confirmationId: string | null;
  showSkip: boolean;
  showBack: boolean;

  constructor(
    private readonly routingStateService: AppointmentRoutingStateService,
    private readonly router: Router,
    private readonly links: LinksService,
    private readonly insuranceCaptureService: InsuranceCaptureService,
    private readonly paymentCaptureService: PaymentCaptureService,
    private readonly analyticsService: AppointmentAnalyticsService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParamMap;
    this.source = params.get(ManageInsuranceQueryParams.SOURCE) as ManageInsuranceSource;
    this.confirmationId = params.get(ManageInsuranceQueryParams.CONFIRMATION_ID);
    this.showSkip = this.isEligibleForInsuranceCaptureEntryPointPage();

    combineLatest([
      this.insuranceCaptureService.getPrimaryInsurance$().pipe(take(1)),
      this.paymentCaptureService.getPaymentCaptureFlag$(),
      this.paymentCaptureService.getPaymentCaptureFlagEnabled$(),
      this.paymentCaptureService.hasPaymentMethods$(),
    ]).subscribe(([insuranceData, paymentCaptureFlag, getPaymentCaptureFlagEnabled, hasPaymentMethods]) => {
      this.hasExistingNonSelfPayInsurance = insuranceData.insuranceStatus !== InsuranceStatus.SELF_PAY;
      this.paymentCaptureFlag = paymentCaptureFlag;
      this.paymentCaptureFlagEnabled = getPaymentCaptureFlagEnabled;
      this.hasPaymentMethods = hasPaymentMethods;
      this.loading = false;

      if (this.isSourceAmazon() && this.hasExistingNonSelfPayInsurance) {
        this.goToDestination();
        return;
      }

      if (!this.currentStep) {
        this.goToInitialStep();
      }
    });
  }

  isSourceReviewBooking(): boolean {
    return this.source === ManageInsuranceSource.REVIEW_BOOKING;
  }

  isSourceBookingConfirmation(): boolean {
    return this.source === ManageInsuranceSource.BOOKING_CONFIRMATION;
  }

  isSourceAppointmentInventory(): boolean {
    return this.source === ManageInsuranceSource.APPOINTMENT_INVENTORY;
  }

  isSourceSwitchToRemote(): boolean {
    return this.source === ManageInsuranceSource.SWITCH_TO_REMOTE;
  }

  isSourceAmazon(): boolean {
    return this.source === ManageInsuranceSource.AMAZON;
  }

  isEligibleForInsuranceCaptureEntryPointPage(): boolean {
    return this.isSourceAppointmentInventory() || this.isSourceSwitchToRemote() || this.isSourceAmazon();
  }

  goBack() {
    if (this.historyStack.length > 1) {
      // If there's local history, go back within component
      this.historyStack.pop();
      const step = this.historyStack.pop() as InsuranceStep;
      this.goToStep(step);
    } else {
      this.goToSource();
    }
  }

  goToStep(step: InsuranceStep): void {
    this.historyStack.push(step);

    this.currentStep = step;
  }

  goToDestination(event?: InsuranceSubmitEvent) {
    let route = this.links.appointmentReview;
    if (this.isSourceBookingConfirmation() || this.isSourceAmazon()) {
      route = this.confirmationId ? this.links.appointmentConfirmation(this.confirmationId) : this.links.home;
    } else if (
      this.isEligibleForInsuranceCaptureEntryPointPage() &&
      ((event?.copay ?? 0) > 0 || event?.insurancePending) &&
      !this.hasPaymentMethods
    ) {
      this.analyticsService.trackPaymentCaptureExperimentVariationAssigned(this.paymentCaptureFlag);
      if (this.paymentCaptureFlagEnabled) {
        this.router.navigate([this.links.managePaymentMethods], {
          queryParams: { [ManagePaymentMethodsQueryParams.SOURCE]: ManagePaymentMethodsSource.INSURANCE },
        });
        return;
      }
    }

    this.router.navigate([route]);
  }

  private goToInitialStep() {
    if (!this.isEligibleForInsuranceCaptureEntryPointPage()) {
      this.goToStep(InsuranceStep.ADD_INSURANCE);
      return;
    }

    this.showBack = !this.isSourceAmazon();
    this.goToStep(InsuranceStep.CONFIRM_INSURANCE);
  }

  private goToSource() {
    if (this.isSourceAppointmentInventory()) {
      this.routingStateService.navigateToStep(AppointmentRoutingStepName.InventoryResults);
      return;
    }

    const routeMap: Partial<Record<ManageInsuranceSource, string>> = {
      [ManageInsuranceSource.REVIEW_BOOKING]: this.links.appointmentReview,
      [ManageInsuranceSource.BOOKING_CONFIRMATION]: this.confirmationId
        ? this.links.appointmentConfirmation(this.confirmationId)
        : this.links.home,
      [ManageInsuranceSource.SWITCH_TO_REMOTE]: this.links.appointmentRescheduleRemote,
    };

    this.router.navigate([routeMap[this.source] || this.links.home]);
  }
}
